<template>
  <div class=''>
    <!-- 检索条件 -->
    <el-form class="radius_bg" :model='searchCondition' inline size='mini' @submit.native.prevent>
      <el-form-item label='类目'>
        <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange' />
      </el-form-item>
      <el-form-item label='商品名称'>
        <el-input placeholder='商品名称' v-model='searchCondition.title' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='审核状态'>
        <el-select v-model='searchCondition.status' placeholder='请选择审核状态' @change='handleQuery' clearable>
          <el-option v-for='item in options' :key='item.value' :label='item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='系统判定' style='display: none'>
        <el-select v-model='searchCondition.check_err' placeholder='请选择系统判定' @change='handleQuery' clearable>
          <el-option label='异常' value='Y'></el-option>
          <el-option label='' value='全部'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model='searchCondition.leader_id' filterable
                   v-if="userPermissions.indexOf('live-product-scope-all') > -1" allow-create
                   placeholder='负责人(可自定义)'
                   clearable
                   @change='changeLeader'>
          <el-option :label='`${item.label} (${item.total})`' :value='item.value' v-for='(item, index) in leaderList'
                     :key='index'></el-option>
        </el-select>
        <span v-else> 负责人：{{ userInfo.nickname }} </span>
      </el-form-item>
      <el-form-item label='可播平台'>
        <el-input v-model='searchCondition.platform_name' clearable placeholder='可播平台' @change='handleQuery' />
      </el-form-item>
      <el-form-item label='提交人'>
        <el-input v-model='searchCondition.contact' clearable placeholder='提交人（品牌联系人）' @change='handleQuery' />
      </el-form-item>
      <el-form-item>
        <el-button type='primary' icon='el-icon-search' @click='handleQuery' :disabled='loading' :loading='loading'>搜索
        </el-button>
      </el-form-item>
      <el-form-item>
        <span class='el-icon-info' style='font-weight: lighter;font-size: 0.9em;color: red;cursor: pointer'
              title='产品手卡导出请到《产品库》页面进行导出'></span>
        <!--        <ExportPpt :multiple-selection='multipleSelection' />-->
      </el-form-item>
      <el-form-item v-if='userPermissions.indexOf("live-product-merchant-brand") > -1' style='float: right'>
        <el-button round type='primary' @click='handleBrand'>商家品牌</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <div class='audit-table default-table radius_bg'>
      <el-table @selection-change='handleSelectionChange' :data='auditData' highlight-current-row
                @sort-change='changeTableSort' :row-class-name='showErr'>
        <el-table-column type='selection' width='55' fixed='left'></el-table-column>

        <el-table-column type='index' label='序号' align='center' fixed='left'></el-table-column>
        <el-table-column label='录入时间' prop='created_at' width='100' align='center' sortable='custom' fixed='left'>
          <template slot-scope='{ row }'>
            <span :title="`审核时间：${row.check_time||''}`">{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label='产品图' width='70' align='center' fixed='left'>
          <template slot-scope='{ row }'>
            <product-image :image-url='row.cover_url' style='width: 40px; height: 40px' />
          </template>
        </el-table-column>
        <el-table-column label='商品名称' prop='title' width='150' align='center' :show-overflow-tooltip='true'
                         fixed='left'>
          <template slot-scope='{ row }'>
            <span>{{ row.title }}</span>
          </template>`
        </el-table-column>
        <el-table-column align='center' label='规格' min-width='100' prop='spec_name'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='色号' min-width='100' prop='spec_color'
                         show-overflow-tooltip></el-table-column>

        <el-table-column label='类目' prop='category_id' min-width='100' align='center' sortable='custom'>
          <template slot-scope='{ row }'>
            <span>{{ row.categoryAll || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='可播平台' prop='platform_name' min-width='120' align='center'>
          <template slot-scope='{row}'>
            <span>{{ String(row.platform_name) || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column align='center' header-align='center' label='合作方式' min-width='110' prop='mode'
                         sortable='custom'>
          <template slot-scope='{ row }' v-if='row.mode'>
            <span>{{ row.mode.display_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='日常价(元)' align='center' min-width='80'>
          <template slot-scope='{ row }'>
            <span>{{ row.price || '/' }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label='直播价(元)' align='center' min-width='80'>
          <template slot-scope='{ row }'>
            <span>{{ row.lb_price || '/' }} </span>
          </template>
        </el-table-column>
        <el-table-column label='赠品/其他' prop='gift' align='center' min-width='120' show-overflow-tooltip>
          <template slot-scope='{ row }'>
            <span>{{ row.gift || '/' }} </span>
          </template>
        </el-table-column>
        <el-table-column label='线上佣金(%)' prop='online_cmn' align='center' min-width='120' sortable='custom'>
          <template slot-scope='{ row }'>
            <span class='rate'>{{ row.online_cmn || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='线下佣金(%)' prop='offline_cmn' align='center' min-width='120' sortable='custom'>
          <template slot-scope='{ row }'>
            <span class='rate'>{{ row.offline_cmn || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='负责人' prop='leader_id' align='center' min-width='100' sortable='custom'>
          <template slot-scope='{ row }'>
            <span>{{ row.leader_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='备注信息' prop='remark' align='center' min-width='140' show-overflow-tooltip>
          <template slot-scope='{ row }'>
            <span>{{ row.remark || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='店铺名称' prop='company_name' align='center' min-width='100' show-overflow-tooltip>
          <template slot-scope='{ row }'>
            <span>{{ row.company_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='提交人' prop='contact' align='center'></el-table-column>
        <el-table-column label='审核状态' prop='status' align='center' width='90' fixed='right'>
          <template slot-scope='{ row }'>
            <el-tag v-if='row.status == 1'>待审核</el-tag>
            <el-tag type='success' v-else-if='row.status == 2'>审核通过</el-tag>
            <el-tag type='info' v-else>审核拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label='拒绝原因' prop='refuse_reason' header-align='center' show-overflow-tooltip>
          <template slot-scope='{ row }'>
            <span>{{ row.refuse_reason }}</span>
          </template>
        </el-table-column>
        <el-table-column label='操作' align='center' fixed='right'>
          <template slot-scope='{ row }'>
            <el-button size='default' type='text' @click='handleEdit(row)' class='el-icon-s-claim'>详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination :hidden='total === 0' :background='true' :total.sync='total' :page.sync='pageData.current_page'
                  :limit.sync='pageData.page_size' @pagination='getList' />
    </div>
    <!-- 审核详情 -->
    <AuditShow :visible.sync='open' @close='getList' :id='currId' :default-form-data='copyRow' />
    <BusinessBrand :visible.sync='brandVisible' @close='brandVisible = false'></BusinessBrand>
  </div>
</template>

<script>
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect'
import ProductCheckLeader from '@/pages/lb/components/ProductCheckLeader'
import ProductImage from '../../ProductImage.vue'
import ProductImageUpload from '@/pages/lb/product/ProductImageUpload'
import { mapGetters } from 'vuex'
import AuditShow from '../../AuditShow.vue'
import ExportPpt from '@/pages/lb/components/ExportPpt'
import BusinessBrand from '@/pages/lb/product/BusinessBrand.vue'

export default {
  name: 'ProductAudit',
  components: {
    ExportPpt,
    CategoryCascader,
    ProductLeaderSelect,
    ProductImage,
    ProductImageUpload,
    AuditShow,
    ProductCheckLeader,
    BusinessBrand
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    currentId() {
      return this.currentRow ? this.currentRow.id : null
    }
  },
  data() {
    return {
      searchCondition: {
        leader_id: null,
        leader_name: null,
        category_id: null,
        title: null,
        status: null,
        category_2_id: null,
        check_err: null,
        platform_name: null
      },
      options: [
        { value: 1, label: '待审核' },
        { value: 2, label: '审核通过' },
        { value: 3, label: '审核拒绝' }
      ],
      auditData: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      orderSort: { id: 'desc' },
      loading: false,
      // 已选中的分类列表，用于cascader组件选中效果
      disabledEditPic: false,
      editLeader: false,
      formData: {},
      refuseDialogVisible: false, //拒绝理由输入框
      refuseForm: { refuse_reason: '' },
      refuseFormRules: {
        refuse_reason: [{ required: true, message: '请输入拒绝理由' }]
      },
      copyRow: null,
      currId: null,
      open: false,
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      searchLeader: '',
      leaderList: [],
      multipleSelection: [],
      brandVisible: false
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    // loopPPt(pres, i) {
    //   let url = String(i.cover_url).replace('https://tisu-pd.oss-cn-hangzhou.aliyuncs.com/', 'http://image.pblab.com/')
    //   let slide = pres.addSlide()
    //   //标题
    //   slide.addText(`${i.company_name}`, {
    //     x: 0.1,
    //     y: 0.1,
    //     w: '60%',
    //     h: 0.3,  //高度
    //     color: '000000',
    //     fill: { color: 'a6a6a6' },
    //     align: 'left',
    //     fontFace: '微软雅黑',
    //     bold: true,
    //     fontSize: 18
    //   })
    //
    //   let formTitle = [
    //
    //     {
    //       title: '商品名称及规格',
    //       value: i.title + '(' + i.spec_name + ')',
    //       bold: true,
    //       color: '000000',
    //       w: 20,
    //       TitleCol: '000000'
    //     },
    //     {
    //       title: '店铺评分',
    //       value: `${i.merchant.score  || '/'}分 `,
    //       bold: true,
    //       color: '000000',
    //       w: 20,
    //       TitleCol: '000000'
    //     }
    //     , {
    //       title: '费用及佣金',
    //       value: (i.ad_space_fee || '-') + '/' + (i.online_cmn || '-') + '%',
    //       bold: false,
    //       color: '000000',
    //       w: 30,
    //       TitleCol: '000000'
    //     }
    //     , { title: '日常标价', value: i.price + '元', bold: false, color: '000000', w: 30, TitleCol: '000000' }
    //     , {
    //       title: '直播间到手价',
    //       value: i.lb_price + '元',
    //       bold: false,
    //       color: '000000',
    //       TitleCol: 'e91414',
    //       w: 30
    //     },
    //     {
    //       title: '赠品',
    //       value: i.gift,
    //       bold: false,
    //       color: '000000',
    //       TitleCol: 'e91414',
    //       w: 30
    //     }
    //     , {
    //       title: '是否全网统一机制',
    //       value: i.unify === 1 ? '是' : '否',
    //       bold: false,
    //       color: '000000',
    //       w: 30,
    //       TitleCol: '000000'
    //     }
    //     , {
    //       title: '是否有签独家',
    //       value: `${i.exclusive === 1 ? '是' : '否'}(${i.other_activity})`,
    //       bold: true,
    //       color: 'e91414',
    //       w: 30,
    //       TitleCol: '000000'
    //     }
    //     , {
    //       title: '是否可签保价1个月',
    //       value: i.support_value === 1 ? '是' : '否',
    //       bold: true,
    //       color: 'e91414',
    //       w: 30,
    //       TitleCol: '000000'
    //     }
    //     , {
    //       title: '历史达播销量',
    //       value: i.sales_volume,
    //       bold: false,
    //       color: 'e91414',
    //       w: 30,
    //       TitleCol: 'e91414'
    //     }
    //     , {
    //       title: '备注',
    //       value: i.remark,
    //       bold: true,
    //       color: '000000',
    //       w: 30,
    //       TitleCol: '000000'
    //     }
    //   ]
    //   let row = []
    //   const options = {
    //     valign: 'middle',
    //     color: '363636',
    //     align: 'left',
    //     fontFace: '微软雅黑',
    //     fontSize: 7.5
    //   }
    //   formTitle.forEach((item) => {
    //     row.push([	// 表格数据
    //       { text: item.title, options: { ...options, color: item.TitleCol } },
    //       { text: item.value, options: { ...options, color: item.color, bold: item.bold } }
    //     ])
    //   })
    //   slide.addTable(row, { // 表格默认配置
    //     x: 0.1,
    //     y: 0.4,
    //     w: 40,
    //     rowH: 0, // 单元格默认高度
    //     valign: 'middle',
    //     fontSize: 8,
    //     color: '666666',
    //     align: 'center',
    //     autoPageHeaderRows: { autoPage: true },
    //     colW: [2.0, 4.5]
    //     // 表格每一列宽度
    //   })
    //   // e9eef4
    //   slide.addText('亮点卖点（简洁）', {
    //     x: 0.1,
    //     y: 2.8,
    //     h: 0.3,  //高度
    //     color: '000000',
    //     align: 'left',
    //     fontFace: '微软雅黑',
    //     bold: true,
    //     fontSize: 12
    //   })
    //   let info = htmlToPptxText(i.light_spot, {
    //     fontFace: '微软雅黑',
    //     fontSize: 7
    //   })
    //   slide.addText(info, {
    //     x: 0.1,
    //     y: 3.1,
    //     w: '60%',
    //     h: '44%',  //高度
    //     color: '000000',
    //     fill: { color: 'f5f7f9' },
    //     align: 'left',
    //     fontFace: '微软雅黑',
    //     fontSize: 8
    //   })
    //   //
    //   slide.addShape(pres.ShapeType.rect, { fill: { color: 'f5f7f9' }, w: 3, h: 3, x: 6.5, y: 0.4 })
    //   slide.addText('商品图片', {
    //     x: 7.5,
    //     y: 0.6,
    //     h: 0.3,  //高度
    //     color: '000000',
    //     align: 'left',
    //     fontFace: '微软雅黑',
    //     bold: true,
    //     fontSize: 16
    //   })
    //   slide.addImage({
    //     path: url,
    //     w: 2,
    //     h: 2,
    //     x: 7.1,
    //     y: 1.1
    //   })
    //   let commodityInfo = i.merchant ? i.merchant.intro : ''
    //   slide.addText(commodityInfo, {
    //     x: 6.2,
    //     y: 4.2,
    //     w: '38%',
    //     h: 'auto',  //高度
    //     color: '000000',
    //     fill: { color: 'f5f7f9' },
    //     align: 'left',
    //     fontFace: '宋体',
    //     fontSize: 10
    //   })
    // },
    //
    // pptExport(command) {
    //   if (this.multipleSelection.length > 0) {
    //     if (command === 'single') {
    //       let pres = new pptxgen()
    //       // http://image.pblab.com/tisu-pd-wx/20230602/TM20230602103726_69391.jpeg
    //       this.multipleSelection.forEach((i) => {
    //         this.loopPPt(pres, i)
    //       })
    //       pres.writeFile({ fileName: '缇苏直播手卡提报.pptx' }).then(() => {
    //         this.$message.success('导出成功')
    //       })
    //     } else {
    //       this.multipleSelection.forEach((i) => {
    //         let pres = new pptxgen()
    //         this.loopPPt(pres, i)
    //         pres.writeFile({ fileName: i.title + '直播手卡提报.pptx' })
    //       })
    //     }
    //
    //   } else {
    //     this.$message.error('请选择要导出手卡的产品')
    //   }
    // },

    handleEdit(row) {
      this.copyRow = {}
      this.currId = row.id
      this.open = true
    },
    //负责人下拉选择事件
    selectedLeader() {
      this.handleQuery()
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    }
    ,
    async getOptions() {
      //get 负责人列表
      this.leaderList = await this.$api.productCheckLeaders()
    }
    ,
    //获取表单数据
    async getList() {
      this.loading = true
      this.auditData = []
      this.searchCondition.value = null
      let searchCondition = this.handleSearchCondition()
      if (this.userPermissions.indexOf('live-product-scope-all') > -1) {
        //有权限
        Object.assign(searchCondition, this.pageData, { order: this.orderSort })
        this.getOptions()
      } else {
        //无权限
        searchCondition.leader_id = this.userInfo.id
        Object.assign(searchCondition, this.pageData, { order: this.orderSort })
      }
      searchCondition.c_type = 1
      let { list, page_info } = await this.$api.getProductAuditList(searchCondition)
      this.auditData = list || []
      this.pageData.current_page = page_info.current_page || 1
      this.pageData.page_size = page_info.page_size || 1
      this.total = page_info.total
      this.loading = false
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.handleQuery()
    },
    //处理搜索条件
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.category_id) {
        cond.category_id = this.searchCondition.category_id
      }
      if (this.searchCondition.category_2_id) {
        cond.category_2_id = this.searchCondition.category_2_id
      }
      if (this.searchCondition.title) {
        cond.title = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.platform_name) {
        cond['platform_name'] = this.searchCondition.platform_name
      }
      if (this.searchCondition.contact) {
        cond['contact'] = this.searchCondition.contact
      }
      if (this.searchCondition.check_err) {
        cond['check_err'] = this.searchCondition.check_err
      }
      // platform_name

      if (this.searchCondition.status) {
        cond.status = this.searchCondition.status
      }
      return cond
    }
    ,
    onClose() {
      this.$refs['elForm'].resetFields()
    }
    ,
    changeLeader(v) {
      this.searchCondition.leader_id = null
      if (v) {
        this.searchCondition.leader_id = v
      }
      this.handleQuery()
      this.selectedLeader = []
    }
    ,
    changeselect(v) {
      this.searchCondition.status = null
      if (v) {
        this.searchCondition.status = v
      }
      this.handleQuery()
    }
    ,
    cascaderChange(v) {
      this.searchCondition.category_id = null
      this.searchCondition.category_2_id = null
      this.searchCondition.category_ids = null
      if (v.length) {
        this.searchCondition.category_id = v[0]
        if (v.length === 2) {
          this.searchCondition.category_2_id = v[1]
        }

        this.formData.category_ids = v
      }
      this.handleQuery()
    },
    handleBrand() {
      this.brandVisible = true
    },
    showErr({ row }) {
      if (row.status === 2 && !row.product_id)
        return 'err-info'

      return ''
    }


  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";

</style>

