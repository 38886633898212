<template>
  <el-dialog fullscreen v-bind='$attrs' title='商家品牌信息' v-on='$listeners' @open='onOpen' :before-close='beforeClose'
             width='880px' center>
    <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
      <el-form-item label='店铺名称'>
        <el-input placeholder='店铺名称(支持模糊搜索)' v-model='searchCondition.seller_name' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='公司名称'>
        <el-input placeholder='公司名称(支持模糊搜索)' v-model='searchCondition.customer_name' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='品牌'>
        <el-input placeholder='品牌(支持模糊搜索)' v-model='searchCondition.brand_name' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='联系人'>
        <el-input placeholder='联系人' v-model='searchCondition.contact_user' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='联系人方式'>
        <el-input placeholder='联系人方式' v-model='searchCondition.contact_number' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item label='微信' style='display: none'>
        <el-input placeholder='微信ID' v-model='searchCondition.openid' @change='handleQuery' clearable />
      </el-form-item>
      <el-form-item>
        <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
      </el-form-item>
    </el-form>
    <div class='default-table'>
      <el-table :data='dataList' highlight-current-row border>
        <el-table-column type='index' label='序号' align='center' min-width='100'></el-table-column>
        <el-table-column align='center' label='店铺名称' min-width='100' prop='seller_name'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='公司名称' min-width='100' prop='customer_name'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='公司品牌' min-width='100' prop='brand_name'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='评分' min-width='100' prop='score'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='公司简介' min-width='100' prop='intro'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='联系人' min-width='100' prop='contact_user'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='联系方式' min-width='100' prop='contact_number'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='邮寄地址' min-width='100' prop='delivery_info'
                         show-overflow-tooltip></el-table-column>
        <el-table-column label='录入时间' prop='created_at' width='120' align='center' sortable='custom'>
          <template slot-scope='{ row }'>
            <span>{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label='修改时间' prop='updated_at' width='140' align='center' sortable='custom'>
        </el-table-column>
        <el-table-column label='操作' prop='updated_at' width='110' align='center'>
          <template slot-scope='{row}'>
            <el-button type='text' icon='el-icon-close' @click='handleDelete(row)'>移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :background='true' :total.sync='total' :page.sync='pageData.current_page'
                  :limit.sync='pageData.page_size' @pagination='getList' />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'BusinessBrand',
  components: {},
  data() {
    return {
      dataList: [],
      pageData: { page_size: 20, current_page: 1 },
      total: 0,
      searchCondition: {}
    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    beforeClose() {
      this.$emit('close')
    },
    //处理搜索条件
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.openid) {
        cond.openid = this.searchCondition.openid
      }
      if (this.searchCondition.seller_name) {
        cond.seller_name = this.searchCondition.seller_name
      }
      if (this.searchCondition.contact_user) {
        cond.contact_user = this.searchCondition.contact_user
      }
      if (this.searchCondition.contact_number) {
        cond.contact_number = this.searchCondition.contact_number
      }
      if (this.searchCondition.brand_name) {
        cond.brand_name = this.searchCondition.brand_name
      }
      if (this.searchCondition.customer_name) {
        cond.customer_name = this.searchCondition.customer_name
      }
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    //获取表单数据
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getMerchantList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleDelete(row) {
      this.$confirm(`该操作将删除商家品牌【${row.brand_name}】，是否继续？移除后若想恢复，请联系管理员！！！`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.delInfo(row.id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    async delInfo(id) {
      if (id) {
        await this.$api.deleteMerchantInfo(id)
        this.msgSuccess('已移除！！！')
        await this.getList()
      }
    }
  }
}
</script>

<style scoped>

</style>
